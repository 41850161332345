var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("Conversation", {
        attrs: {
          message_uuid: _vm.message_uuid,
          functionbox_uuid: _vm.functionbox_uuid,
          user_uuid: _vm.user_uuid,
          conversation_uuid: _vm.conversation_uuid,
        },
        on: { gotoMessage: _vm.gotoMessage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }