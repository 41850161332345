<template>
  <section>
    <Conversation
      :message_uuid="message_uuid"
      :functionbox_uuid="functionbox_uuid"
      @gotoMessage="gotoMessage"
      :user_uuid="user_uuid"
      :conversation_uuid="conversation_uuid"></Conversation>
  </section>
</template>
<script>
import Conversation from "@/components/Conversation/Conversation";
export default {
  props: ["message_uuid", "functionbox_uuid", "user_uuid", "conversation_uuid"],
  components: {
    Conversation
  },
  data() {
    return {
    };
  },
  methods: {
    gotoMessage() {
      console.log("gotoMessage456");
      this.$emit("gotoMessage", this.message.message_uuid);
    },
  },
  mounted() {
  },
};
</script>
<style lang="css"></style>
